import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProductService } from 'src/app/shared/services/product.service';

@Component({
  selector: 'app-browse-log-item',
  templateUrl: './browse-log-item.component.html',
  styleUrls: ['./browse-log-item.component.scss']
})
export class BrowseLogItemComponent implements OnInit {
  @Input() product: any;
  @Output() listUpdated = new EventEmitter<string>();

  urlimage = this.commonService._ImageUrl + "products/thumbs/";
  handleImage(e){
    e.src = 'assets/images/product/placeholder.jpg';
  }
  constructor(
    public productService: ProductService,
    private toastrService: ToastrService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // console.log(this.product);
    
  }
  handleImageError(e){
    e.src = 'assets/images/product/placeholder2.jpg';
  }
  removeBrackets(str: string): string {
    return str.replace(/[\()%/]/g, '');
  }
  removeItem(productId: any) {
    this.productService
      .deleteBrowseProductHistory(productId)
      .subscribe((data) => {
        if (data.success) {
          this.toastrService.error(data.message);
          this.listUpdated.emit('list updated');
          
          // this.products.splice(index, 1);
        }
      });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // console.log('product in browse item ', this.product);
    
  }

  async addToCart(product: any) {
    const status = await this.productService.addToCart(product);
    if (status) {
      this.router.navigate(["/shop/cart"]);
      // this.removeItem(product);
    }
  }

}

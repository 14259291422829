<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'home' | translate}}</a></li>
                        <!-- <li class="breadcrumb-item" aria-current="section">{{'category' | translate}} </li> -->
                        <!-- <li class="breadcrumb-item active" aria-current="page"> {{category_name.split('-').join(' ') | translate}}</li>
                        <li class="breadcrumb-item active" aria-current="page" *ngIf="breadCrumbList[1]?.length > 0">
                            <a style="cursor: pointer"
                                [routerLink]="['/shop/category', breadCrumbList[0].id, breadCrumbList[0].ser_name]">
                                {{breadCrumbList[0]?.ser_name}}
                            </a>
                        </li> -->

                        <!-- <li class="breadcrumb-item active" aria-current="page" *ngIf="breadCrumbList[1]">
                            {{breadCrumbList[0]?.ser_name}} 

                        </li> -->

                        <!-- <li class="breadcrumb-item active" aria-current="page" *ngIf="breadCrumbList[1]?.ser_name.length > 0" >
                          
                            {{breadCrumbList[1]?.ser_name}}
                        </li> -->

                        <a class=""
                            [routerLink]="['/shop/category' , breadCrumb.id , breadCrumb.ser_name.split(' ').join('-')]"
                            *ngFor="let breadCrumb of breadCrumbList ;  let isFirst = first ; let isLast = last">>
                            {{breadCrumb.ser_name | translate}}</a>

                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>


<!-- <div class="page-title">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2>{{category_name}}</h2>
            </div>
        </div>
    </div>
</div> -->

<!-- section start -->
<section class="section-b-space">

    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <h1 class="category-up-title">
                    {{offernamebool? offername :category_name | translate}}
                </h1>
            </div>
        </div>
    </div>

    <div class="collection-wrapper">
        <div class="container">
            <div class="row ">
                <div class="col-lg-2">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->

                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->

                        <div>

                            <!-- CATEGORIES FILTER -->
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                <span class="filter-back">

                                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                                    {{ 'Back' | translate }}&nbsp;&nbsp;
                                </span>
                            </div>
                            <div class="loader-block d-flex justify-content-center" *ngIf="filterLoading">
                                <img src="assets/images/loader.gif">
                            </div>
                            <div class="collection-filter-block" *ngIf="!filterLoading">


                                <!-- PRICE FILTER -->
                                <ngb-accordion class="accordion1" #acc="ngbAccordion">
                                    <ng-container *ngFor="let price of filterDataPrice; let i = index">
                                        <ngb-panel id="{{ 'formshapepanel' + price.mode}}"
                                            *ngIf="price.type == 'select' || price.type == 'm_select'">
                                            <ng-template ngbPanelTitle>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                                        {{price.label}}
                                                    </div>
                                                    <div class="d-flex"><i class="ti-angle-down"></i></div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <ng-container *ngFor="let options of price.options; let j = index">
                                                    <div *ngIf="options.label" class="accordion-content">

                                                        <input type="checkbox" id="{{ 'price_' + i + '_' + j }}"
                                                            name="vehicle1" value="Bike"
                                                            (change)="checkValuePrice($event, options.value)"
                                                            [checked]="pricesExists(options.value)">
                                                        &nbsp;
                                                        <label for="{{ 'price_' + i + '_' + j }}">
                                                            {{options.label}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </ngb-panel>
                                    </ng-container>
                                </ngb-accordion>
                                <!-- FILTER TYPE -->
                                <ngb-accordion class="accordion1" #acc="ngbAccordion">
                                    <ng-container *ngFor="let filterType of filterDataType; let i = index">
                                        <ngb-panel id="{{ 'formshapepanel' + filterType.mode}}"
                                            *ngIf="filterType.type == 'select' || filterType.type == 'm_select'">
                                            <ng-template ngbPanelTitle>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                                        {{filterType.label}}
                                                    </div>
                                                    <div class="d-flex"><i class="ti-angle-down"></i></div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <ng-container *ngFor="let options of filterType.options; let j = index">
                                                    <div *ngIf="options.label" class="accordion-content">

                                                        <input type="checkbox" id="{{ 'type_' + i + '_' + j }}"
                                                            name="vehicle1" value="Bike"
                                                            (change)="checkValueType($event, options.value)"
                                                            [checked]="typesExists(options.value)">
                                                        &nbsp;
                                                        <label for="{{ 'type_' + i + '_' + j }}">
                                                            {{options.label}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </ngb-panel>
                                    </ng-container>
                                </ngb-accordion>

                                <!-- BRANDS FILTER -->
                                <ngb-accordion class="accordion1" #acc="ngbAccordion">
                                    <ng-container *ngFor="let brand of filterDataBrand; let i = index">
                                        <ngb-panel id="{{ 'formshapepanel' + brand.mode}}"
                                            *ngIf="brand.type == 'select' || brand.type == 'm_select'">
                                            <ng-template ngbPanelTitle>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                                        {{brand.label}}
                                                    </div>
                                                    <div class="d-flex"><i class="ti-angle-down"></i></div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <ng-container *ngFor="let options of brand.options; let j = index">
                                                    <div *ngIf="options.label" class="accordion-content">

                                                        <input type="checkbox" id="{{ 'brand_' + i + '_' + j }}"
                                                            name="vehicle1" value="Bike"
                                                            (change)="checkValueBrand($event, options.value, options.label)"
                                                            [checked]="brandsExists(options.value)">
                                                        &nbsp;
                                                        <label for="{{ 'brand_' + i + '_' + j }}">
                                                            {{options.label}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </ngb-panel>
                                    </ng-container>
                                </ngb-accordion>


                                <!-- FORM SHAPE FILTER -->
                                <ngb-accordion class="accordion1" #acc="ngbAccordion">
                                    <ng-container *ngFor="let formshape of filterDataShapes; let i = index">
                                        <ngb-panel id="{{ 'formshapepanel' + formshape.id}}"
                                            *ngIf="formshape.type == 'select' || formshape.type == 'm_select'">
                                            <ng-template ngbPanelTitle>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex" style="font-weight: bold; color: #1c2128;">
                                                        {{formshape.label | translate}}
                                                    </div>
                                                    <div class="d-flex"><i class="ti-angle-down"></i></div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <ng-container
                                                    *ngFor="let formoption of formshape.options; let j = index">
                                                    <div *ngIf="formoption.label" class="accordion-content">

                                                        <input type="checkbox" id="{{ 'option_' + i + '_' + j }}"
                                                            name="vehicle1" value="Bike" (change)="checkValueForm($event, formshape.id,
                                                        formoption.value)"
                                                            [checked]="formShapeExists(formshape.id,formoption.value)">

                                                        &nbsp;
                                                        <label for="{{ 'option_' + i + '_' + j }}">
                                                            {{formoption.label}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </ngb-panel>
                                    </ng-container>
                                </ngb-accordion>

                            </div>
                        </div>

                        <!-- <div class="collection-filter-block"> -->



                        <!-- <div class="submit-filter-button border-test1" (click)="updateFilter1()">
                                Submit Filter
                            </div>

                            <app-price [min]="minPrice" [max]="maxPrice" (priceFilter)="updateFilter($event)">
                            </app-price> -->

                        <!-- brand filter start -->
                        <!-- <app-brands [products]="products" [brands]="brands" (brandsFilter)="updateFilter($event)">
                            </app-brands> -->
                        <!-- color filter start here -->
                        <!-- <app-colors [products]="products" [colors]="colors" (colorsFilter)="updateFilter($event)">
                            </app-colors> -->
                        <!-- size filter start here -->
                        <!-- <app-size [products]="products" [size]="size" (sizeFilter)="updateFilter($event)">
                            </app-size> -->
                        <!-- price filter start here -->


                        <!-- FORM SHAPE FILTER -->
                        <!-- <div *ngFor="let formshape of filterData" class="collection-collapse-block"
                                [class.open]="formShapeCollapse">
                                <h3 class="collapse-block-title" (click)="formShapeCollapse = !formShapeCollapse">
                                    {{formshape.label}}</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="mt-2" *ngFor="let formoption of formshape.options">
                                            {{formoption.label}}
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        <!-- </div> -->


                        <!-- price filter start here -->

                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar single product slider start -->
                        <!-- <app-product-box-vertical-slider [title]="'New Products'">
                        </app-product-box-vertical-slider> -->
                        <!-- side-bar single product slider end -->
                        <!-- side-bar banner start here -->
                        <!-- <div class="collection-sidebar-banner">
                            <a [routerLink]="['/shop/collection/left/sidebar']">
                                <img src="assets/images/collection-sidebar-banner.png" class="img-fluid" alt="banner">
                            </a>
                        </div> -->
                        <!-- side-bar banner end here -->
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <div class="top-banner-wrapper">
                                    <a [routerLink]="['/shop/collection/left/sidebar']">
                                        <img src="assets/images/collection-banner.jpg" class="img-fluid " alt="">
                                    </a>
                                    <div class="top-banner-content small-section">
                                        <h4>fashion</h4>
                                        <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </h5>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has
                                            survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                            and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                        </p>
                                    </div>
                                </div> -->
                                <div class="row filters-button-layout">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                                <i class="fa fa-filter" aria-hidden="true"></i>
                                                {{'filters' | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <ul class="product-filter-tags ">
                                    <li class="mr-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            Clear all
                                        </a>
                                    </li>
                                </ul> -->
                                <div class="filter-tags-block d-flex justify-content-between align-items-stretch">
                                    <div class="d-flex justify-content-between align-content-stretch w-100">

                                        <div class="buttons-block">
                                            <!-- <pre>{{ filterBrandsNamesButtons | json }}</pre> -->
                                            <div class="tag-button" *ngFor="let brandTag of filterBrandsNamesButtons"
                                                (click)="removeBrandTag(brandTag.value)">
                                                <span>
                                                    {{brandTag.label}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>
                                            <div class="tag-button" *ngFor="let formTag of filterFormsNamesButtons"
                                                (click)="removeFormTag(formTag.value, formTag.formId)">
                                                <span>
                                                    {{formTag.label}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>
                                            <div class="tag-button" *ngFor="let formTag of filterPricesNamesButtons"
                                                (click)="removePriceTag(formTag.from, formTag.to)">
                                                <span>
                                                    {{formTag.from}} - {{formTag.to}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>
                                            <div class="tag-button" *ngFor="let formTag of filterTypes"
                                                (click)="removeFilterTypeTag(formTag)">
                                                <span *ngIf="formTag === 'soon'">
                                                    {{'Soon'|translate}}
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="formTag === 'in_stock'">
                                                    {{'available'|translate}}
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="formTag === 'discounts'">
                                                {{'discounts-collections'|translate}}
                                                    <i class="ti-close"></i>
                                                </span>
                                                <span *ngIf="formTag === 'offers'">
                                                    {{'offers-collections'|translate}}
                                                    <i class="ti-close"></i>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="clear-all-tags text-nowrap" *ngIf="filterBrandsNamesButtons.length !== 0 || filterTypes.length !== 0 || filterFormsNamesButtons.length !== 0
                                    || filterPricesNamesButtons.length !== 0">
                                            <span (click)="removeAllTags()">
                                                {{"clear all" | translate}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="mx-2 border-light d-flex align-items-center">
                                        <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView"
                                            [sortBy]="sortBy" (mobileSidebarChange)="toggleMobileSidebar()"
                                            (sortedBy)="sortByFilter($event)">
                                        </app-grid>
                                    </div>
                                </div>
                                <div class="collection-product-wrapper" id="products">

                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row">
                                            <div class="col-grid-box" [ngClass]="grid" style=" margin: 5px; "
                                                *ngFor="let product of allShownProducts">
                                                <!-- <div class="product-box"> -->
                                                <app-product-box-one [product]="product"
                                                    [currency]="productService?.Currency" [thumbnail]="false"
                                                    [cartModal]="true">
                                                </app-product-box-one>
                                                <!-- </div> -->
                                            </div>
                                            <div class="mt-5 text-center col-sm-12 section-b-space no-found"
                                                *ngIf="finished &&!allItems.length &&!allShownProducts.length">
                                                <img src="assets/images/empty-search1.png" class="mb-4 img-fluid">
                                                <h3>{{'Sorry Couldn find the product' | translate}}</h3>
                                                <p></p>
                                                <a [routerLink]="['/']" class="btn btn-solid">{{'continue shopping' |
                                                    translate}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Infinite Scroll -->
                                    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="1000"
                                        (scrolled)="onScroll()" *ngIf="allItems.length">
                                    </div>
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="p-0 container-fluid">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="mt-3 mb-3 infinite-scrolling d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="!finished">
                                                            <div class="loading-more active"
                                                                *ngIf="finished &&!allItems.length">
                                                                {{'No More Products' | translate}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->